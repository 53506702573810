import React from 'react'

// Icons
import CoverImage from '../../molecules/CoverImage'

// Component
import VariantCarrousel from '../../molecules/VariantCarrousel'

// Styles
import {
  variantWrapper,
  imageContainer,
} from './style.module.scss'

interface Props {
  name: string
  cover: string | null
  variants: string[]
}
const ComicImages: React.FC<Props> = ({ name, cover, variants }) => {
  return (
    <div className={imageContainer}>
      <CoverImage name={name} cover={cover} />
      <div className={variantWrapper}>
        {variants?.length > 0 && (
          <VariantCarrousel
            variants={variants}
          />
        )}
      </div>
    </div>
  )
}

export default ComicImages

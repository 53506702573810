import React, { useContext, useState } from 'react'
import AliceCarousel, { EventObject } from 'react-alice-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import VariantContext from '../../../context/Variant/Variant.context'

// Style
import {
  next,
  prev,
  navButton,
  thumbnail,
  thumbnailSlide,
  homeCarrouselWrapper,
} from './style.module.scss'

interface Props {
  variants: string[]
}

const VariantCarrousel: React.FC<Props> = ({ variants }) => {
  const responsive = {
    0: { items: 3 },
    399: { items: 4 },
    499: { items: 5 },
    599: { items: 6 },
    767: { items: 4 },
    1023: { items: 4 },
  }

  const { updateVariant } = useContext(VariantContext)
  const [thumbIndex, setThumbIndex] = useState(0)

  const renderSlides = () =>
    variants.map((v, index) => (
      <div className={thumbnailSlide}>
        <img
          onClick={() => {
            updateVariant(v)
          }}
          className={thumbnail}
          key={`thumb_${v}`}
          src={`https://comiccolombiano.com/.img/variants/${v}`}
          loading="lazy"
        />
      </div>
    ))

  return (
    <div className={homeCarrouselWrapper}>
      <AliceCarousel
        activeIndex={thumbIndex}
        items={renderSlides()}
        responsive={responsive}
        infinite
        mouseTracking
        animationType={'slide'}
        disableDotsControls
        onSlideChanged={(e: EventObject) => {
          if (e.item === variants.length) setThumbIndex(0)
          else if (e.item < 0) setThumbIndex(variants.length - 1)
          else setThumbIndex(e.item)
        }}
        renderPrevButton={() => (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={[navButton, prev].join(' ')}
          />
        )}
        renderNextButton={() => (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={[navButton, next].join(' ')}
          />
        )}
      />
    </div>
  )
}

export default VariantCarrousel
